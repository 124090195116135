<template>
  <div id="main" class="main">
    <!-- 头部 -->
    <Header/>
    <!-- 主体 -->
    <div class="content">
      <!-- 内容 -->
      <div class="minBg">
        <img class="map" :src="minBg" alt="" />
        <div id="sceneSelect" class="topSelect" style="display: flex;">
          <template v-for="(item,index) in competitionGame_top" :key="index+1">
            <div v-if="item.disabled" id="competition" @click="debugging(item)">
              <img  :src="item.src" />
            </div>
            <div v-else id="competitionGray" >
              <img  :src="item.disabledSrc" />
            </div>
          </template>
          
        </div>
        <div id="sceneSelect" class="bottomSelect" style="display: flex;">
          <template v-for="(item,index) in competitionGame_bottom" :key="index+1">
            <div v-if="item.disabled" id="competition" >
              <img  :src="item.src" />
            </div>
            <div v-else id="competitionGray" >
              <img  :src="item.disabledSrc" />
            </div>
          </template>
          
        </div>
        <!-- <div v-for="(item, index) in pictureArray" :key="index">
          <img :style="{ opacity: item.opacity }" :id="(item.opacity == 0) ? 'breathLight' : ''" :class="item.class"
            :src="item.src" :title="item.name" @click="select(item.name)" />
          <span :class="item.css">{{ item.name }}</span>
        </div> -->
        <!-- <img class="startProcess" @click="debugging" @mouseover="startover" @mouseleave="startleave" :src="start" alt="" /> -->
      </div>
    </div>
    <!-- 中信科logo -->
    <img class="logo" @click="logo" src="./img/logo_1.png" alt="">
    <!-- 小唐人弹出框 -->
    <div class="logoBox" v-show="dialogVisible">
      <span>
        请根据设计来确定所需的创新应用场景，通过点击该场景对应的图标进行选择，点击“开始调试”按钮，进入后续的通信设备配置部署和指标呈现等设计页面。
      </span>
      <div class="logoAngle"></div>
    </div>
    <!-- 尾部 -->
    <Footer/>
  </div>
</template>


<script>
import Footer from "../home/footer.vue";
import Header from "../home/header.vue";
import { Menu, EditPen, Message, ArrowRight } from "@element-plus/icons-vue";
export default {
  name: "home",
  props: {
    // msg: String
  },
  components: {
    Header,
    Footer,
    Menu,
    EditPen,
    Message,
    ArrowRight,
  },
  data() {
    return {
      minBg: require("./img/minBg.jpg"),//初始背景图片
      start: require("./img/start_2.png"),//开始调试按钮图片
      dialogVisible: true,
      selectedScene: [],
      competitionGame_top:[
        {
          index:'One',
          src: require("./img/contestOne.png"),
          name: '无线通信物理层链路仿真设计',
          disabled:true,
        },
        {
          index:'Two',
          src: require("./img/contestTwo.png"),
          disabledSrc:require("./img/contestTwoGray.png"),
          name: '5G+软件无线电创新设计',
          disabled:false
        },
        {
          index:'Three',
          src: require("./img/contestThree.png"),
          disabledSrc:require("./img/contestThreeGray.png"),
          name: '5G+网联智能车创新设计',
          disabled:false
        },
        
      ], //赛道top列表
      competitionGame_bottom:[
      {
          index:'Four',
          src: require("./img/contestFour.png"),
          disabledSrc:require("./img/contestFourGray.png"),
          disabled:false
        },
        {
          index:'Five',
          src: require("./img/contestFive.png"),
          disabledSrc:require("./img/contestFiveGray.png"),
          disabled:false
        }
      ] //赛道bottom列表
    };
  },
  watch: {},
  mounted() {
  },
  methods: {
    //点击图片触发事件
    select(name) {
      this.pictureArray.map((item, index) => {
        if (item.name == name && item.opacity == 0) {
          item.opacity = 1;
          this.selectedScene .push(name);
        } else if (item.name == name && item.opacity == 1) {
          item.opacity = 0;
          this.selectedScene.map((item, index) => {
            if (item == name) {
              this.selectedScene.splice(index, 1);
            }
          });
        }
      });
    },
    //logo点击事件
    logo() {
      this.dialogVisible = !this.dialogVisible;
    },
    // 鼠标滑过事件
    startleave() {
      this.start = require("./img/start_2.png");
    },
    // 鼠标滑过事件
    startover() {
      this.start = require("./img/start_1.png");
    },
    //开始调试按钮
    debugging(index) {
      window.open('https://event.fmaster.cn/event/1772075020211818498/stage/1772075497049657345/task/1783053875365400577','_blank')
      // if (this.selectedScene.length == 0) {
      //   this.$message.error("请您选择需要调试的项目！");
      //   return false;
      // }
      // if(index) {
      //   switch (index) {
      //     case 'One':
      //       this.$router.push("/innovation");
      //       break;
      //       case 'Two':
      //       this.$router.push("/innovation");
      //       break;
      //       case 'Three':
      //       this.$router.push("/innovation");
      //       break;
      //       case 'Four':
      //       this.$router.push("/innovation");
      //       break;
      //       case 'Five':
      //       this.$router.push("/innovation");
      //       break;
      //     default:
      //       break;
      //   }
      // }
      // this.$store.commit("Scene", this.selectedScene);
      // this.$router.push("/innovation");
    },
  },
};
</script>

<style scoped>
@import "./css/index.css";
@import "./css/content.css";
</style>
<template>
  <!-- 尾部 -->
  <div class="footerBox">
    <span style="margin-left: 3rem;">
      <span>学校：</span><span>XXXXXX</span>
      <span style="margin-left: 1rem;">团队：</span><span>XXXXXX</span>
    </span>

    <span class="belong">
      版权所有: 中信科移动通信技术股份有限公司-培训中心
    </span>
  </div>
</template>


<script>
export default {
  name: "master",
  props: {
    // msg: String
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
@import "./css/index.css";
</style>

<template>
	<!-- 头部 -->
	<div>
		<div class="h-header">
			<img  src="./img/logo.png" alt="">
			<span class="text">第十一届“大唐杯”全国大学生新一代信息通信技术大赛 — 5G+创新应用设计赛</span>
		</div>
	</div>
</template>


<script>
	export default {
		data() {
			return {

			}
		},
		methods:{
		}
	}
</script>

<style scoped>
	@import './css/index.css';
</style>

import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/Home.vue';
import Home from '@/views/home/index.vue';



const routes = [
  {
    path: '/',//首页
    name: 'Home',
    component: Home,
  },
    {
    path: '/innovation',
    name: 'innovation',
    component: () => import('../views/home/innovation.vue')
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: () => import('../views/home/equipment.vue')
  },
  {
    path: '/hardwareExploit',
    name: 'hardwareExploit',
    component: () => import('../views/home/hardwareExploit.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
